import Helmet from "react-helmet";
import React from "react";
import { Footer } from "components/Footer";
import { FooterMenu } from "components/FooterMenu";
import { Layout } from "components/Layout";
import { Link } from "gatsby";
import { RawContent } from "components/RawContent";
import { RouteData } from "@trunkery/internal/lib/vatureTypes";
import { useSiteData } from "utils/useSiteData";

import { T } from "./Page.tlocale";

export default ({ pageContext }: { pageContext: RouteData.Page }) => {
  const { shop } = useSiteData();
  const { page } = pageContext;
  return (
    <Layout>
      <Helmet defer={false}>
        <meta name="title" content={page.meta_title} />
        <meta name="description" content={page.meta_description} />
        <title>
          {shop.name} | {page.title}
        </title>
      </Helmet>
      <div className="page-with-menu">
        <div className="page-with-menu__content">
          <div className="breadcrumbs">
            <Link to="/">{T("Home")}</Link>
            {" / "}
            {page.title}
          </div>
        </div>
        <div className="page-with-menu__content">
          <RawContent
            rawContent={page.raw_content}
            contentImageFiles={page.content_image_files}
            className="product-description"
          />
        </div>
        <div className="page-with-menu__content">{/* TODO: include "bits/you-might-also-like.liquid" */}</div>
        <div className="page-with-menu__content page-with-menu__content--no-padding page-with-menu__content--no-max-width">
          <FooterMenu />
          <Footer />
        </div>
      </div>
    </Layout>
  );
};
